import * as React from 'react';
import Head from 'next/head';

import { PushButton } from '@/components/push-button/push-button';

import { H1 } from '@/components/h1/h1';

import style from '@/pages/pages.module.scss';
import { track } from '@/track';

// markup
const NotFoundPage = () => {
  return (
    <main className={style.root}>
      <Head>
        <title>Not found</title>
      </Head>
      <H1>Page not found</H1>
      <div>
        <p>Sorry we couldn’t find what you were looking for.</p>
        <br />
        <br />
        <PushButton href="/" onClick={() => track('404', {})}>
          Go home
        </PushButton>
      </div>
    </main>
  );
};

export default NotFoundPage;
