import React from 'react';
import classNames from 'classnames';
import style from './h1.module.css';

type Props = {
  children: string;
  className?: string;
};

export function H1(props: Props) {
  const { className, children } = props;
  return <h1 className={classNames(style.root, className)}>{children}</h1>;
}
